.brands {
  width: 100%;
  padding: 50px 20px;
}
.brands__list {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.brands__list.rows1 {
  grid-template-rows: repeat(1, 1fr);
}
.brands__list.rows2 {
  grid-template-rows: repeat(2, 1fr);
}
.brands__item{
  margin: 10px 15px;
}
.brands__item__img {
  max-width: 120px;
  max-height: 120px;
  object-fit: contain;
}
