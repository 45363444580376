.App-logo {
  width: 200px;
}
.App-header {
  width: 100%;
  background-color: rgb(40, 75, 118);
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 10px;
  /* color: white; */
}
.header__products {
  border-style: none;
  line-height: 35px;
  padding: 0 1.5rem;
  background-color: brown;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
  cursor: pointer;
}
@media (max-width: 450px) {
  .App-logo {
    width: 160px;
  }
}

@media (max-width: 350px) {
  .App-logo {
    width: 120px;
  }
}
