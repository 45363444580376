.header {
  background: url(/static/media/wave-alt.ffff0f68.svg);
  background-size: cover;
  background-position: 0 -10vw;
  background-repeat: no-repeat;

  position: relative;
  height: 25vw;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: right;
  align-content: start;
  padding: 0 5vw;
}
.header__brand{
  margin-top: 5vw;
}
.header__brand .logo{
  width: 20vw;
}
.header__content{
  margin-top: 9vw;
}
.hero__title {
  font-family: "Staatliches", cursive;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 1px;
  z-index: 2;
}
.hero__details {
  z-index: 2;
}

@media (max-width: 1024px) {
  .header{
    height: initial;
    grid-auto-flow: row;
    background-position: 0 -60vw;
    margin-bottom: 5vw;
  }
  .header__brand{
    display: flex;
  }
  .header__brand .logo{
    width: 35vw;
  }
  .header__content{
    text-align: center;
  }
  .hero__title{
    margin-top: 10vw;
  }

  .features__title{
    text-align: center;
  }
}