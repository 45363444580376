.footer {
  width: 100%;
  background: #292e31;
}
.footer--wrapper {
  max-width: 1633px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-flow: column;
  grid-gap: 40px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 50px 100px;
}
.footer * {
  color: #fff;
}
.footer__actions {
  display: grid;
  align-content: space-between;
  padding: 5px;
}
.actions__call {
  display: grid;
  grid-template: auto auto / auto auto;
  grid-gap: 10px;
}
.actions__call p {
  grid-column: 1 / span 2;
  font-size: 14px;
}
.call__button {
  border-style: none;
  background-color: #bc8a2693;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.footer__card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 30px;
}
.card__top {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}
.redes__list {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
}
.redes__item {
  background: #292e31;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
}
.menu__secundary_list {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.secundary_list__item {
  margin-left: 30px;
  text-shadow: 1px 1px 4px black;
  font-weight: bold;
}
.card__bottom {
  display: grid;
  grid-template: auto auto / 2fr 1fr;
}
.bottom__copy {
  font-size: 12px;
}
.bottom__desc {
  width: 360px;
  grid-row: 2;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: justify;
}

.footer__input {
  color: #bc8b26;
  border-style: none;
  background-color: black;
  border-radius: 4px;
  padding: 5px 10px;
  outline-color: #bc8b26;
}
@media (max-width: 900px) {
  .footer--wrapper{
    grid-template-columns: 1fr;
  }
  .footer__actions{
    display: none;
  }
  .menu__secundary_list{
    display: none;
  }
}

@media (max-width: 700px) {
  .footer--wrapper{
    padding: 50px;
  }
  .footer__card{
    padding: 15px;
  }
  .card__bottom{
    grid-template: auto auto / 2fr;
  }
  .bottom__desc{
    width: 100%;
  }
}
