.product_card {
  width: 160px;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
}
.product_card__photo {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.product_card__name {
  font-size: 16px;
}
