@import url(https://fonts.googleapis.com/css?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;1,200;1,300&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  width: 200px;
}
.App-header {
  width: 100%;
  background-color: rgb(40, 75, 118);
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 10px;
  /* color: white; */
}
.header__products {
  border-style: none;
  line-height: 35px;
  padding: 0 1.5rem;
  background-color: brown;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
  cursor: pointer;
}
@media (max-width: 450px) {
  .App-logo {
    width: 160px;
  }
}

@media (max-width: 350px) {
  .App-logo {
    width: 120px;
  }
}

.separator {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}
.separator p {
  font-size: 14px;
  color: rgba(40, 44, 54, 0.4);
  font-weight: bold;
  margin: 0 1rem;
}
.separator hr {
  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #dddddd;
}
.separator hr:first-child {
  width: 100%;
  justify-self: end;
}

.brands {
  width: 100%;
  padding: 50px 20px;
}
.brands__list {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.brands__list.rows1 {
  grid-template-rows: repeat(1, 1fr);
}
.brands__list.rows2 {
  grid-template-rows: repeat(2, 1fr);
}
.brands__item{
  margin: 10px 15px;
}
.brands__item__img {
  max-width: 120px;
  max-height: 120px;
  object-fit: contain;
}

.footer {
  width: 100%;
  background: #292e31;
}
.footer--wrapper {
  max-width: 1633px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-flow: column;
  grid-gap: 40px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 50px 100px;
}
.footer * {
  color: #fff;
}
.footer__actions {
  display: grid;
  align-content: space-between;
  padding: 5px;
}
.actions__call {
  display: grid;
  grid-template: auto auto / auto auto;
  grid-gap: 10px;
}
.actions__call p {
  grid-column: 1 / span 2;
  font-size: 14px;
}
.call__button {
  border-style: none;
  background-color: #bc8a2693;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.footer__card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 30px;
}
.card__top {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}
.redes__list {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
}
.redes__item {
  background: #292e31;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
}
.menu__secundary_list {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.secundary_list__item {
  margin-left: 30px;
  text-shadow: 1px 1px 4px black;
  font-weight: bold;
}
.card__bottom {
  display: grid;
  grid-template: auto auto / 2fr 1fr;
}
.bottom__copy {
  font-size: 12px;
}
.bottom__desc {
  width: 360px;
  grid-row: 2;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: justify;
}

.footer__input {
  color: #bc8b26;
  border-style: none;
  background-color: black;
  border-radius: 4px;
  padding: 5px 10px;
  outline-color: #bc8b26;
}
@media (max-width: 900px) {
  .footer--wrapper{
    grid-template-columns: 1fr;
  }
  .footer__actions{
    display: none;
  }
  .menu__secundary_list{
    display: none;
  }
}

@media (max-width: 700px) {
  .footer--wrapper{
    padding: 50px;
  }
  .footer__card{
    padding: 15px;
  }
  .card__bottom{
    grid-template: auto auto / 2fr;
  }
  .bottom__desc{
    width: 100%;
  }
}

.header {
  background: url(/static/media/wave-alt.ffff0f68.svg);
  background-size: cover;
  background-position: 0 -10vw;
  background-repeat: no-repeat;

  position: relative;
  height: 25vw;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  text-align: right;
  align-content: start;
  padding: 0 5vw;
}
.header__brand{
  margin-top: 5vw;
}
.header__brand .logo{
  width: 20vw;
}
.header__content{
  margin-top: 9vw;
}
.hero__title {
  font-family: "Staatliches", cursive;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 1px;
  z-index: 2;
}
.hero__details {
  z-index: 2;
}

@media (max-width: 1024px) {
  .header{
    height: auto;
    height: initial;
    grid-auto-flow: row;
    background-position: 0 -60vw;
    margin-bottom: 5vw;
  }
  .header__brand{
    display: flex;
  }
  .header__brand .logo{
    width: 35vw;
  }
  .header__content{
    text-align: center;
  }
  .hero__title{
    margin-top: 10vw;
  }

  .features__title{
    text-align: center;
  }
}
.product_card {
  width: 160px;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
}
.product_card__photo {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.product_card__name {
  font-size: 16px;
}

.arrow {
  line-height: 0;
  position: absolute;
  top: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 22px;
  outline: none;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}
.arrow {
  fill: rgba(69, 91, 117, 0.5);
}
.arrow__next {
  right: -10px;
  border-left: 1px solid rgba(69, 91, 117, 0.2);
}
.arrow__prev {
  left: -10px;
  border-right: 1px solid rgba(69, 91, 117, 0.2);
}

/* Products View */
.products {
  min-height: 100vh;
  background-color: #f6f6f7;
}
.products--wrapper {
  display: grid;
  grid-template-columns: 240px 1fr;
  padding: 30px 15px;
}

/* Categories Component */
.categories {
  display: grid;
}
.products__categories {
  /* padding: 30px; */
  list-style: none;
  padding: 15px;
}
.categories__item {
  font-weight: 600;
  padding: 4px 0;
  font-size: 14px;
  cursor: pointer;
  color: #7d7d7d;
}
.products__categories--responsive {
  display: none;
  width: 500px;
  justify-self: center;
}
.categories__item--responsive {
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 5px;
}
.categories__item--responsive p {
  margin: 0;
  padding: 0;
}
.slick-slide.slick-active.slick-center.slick-current
  .categories__item--responsive {
  max-width: 200px;
  font-weight: bold;
  font-size: 18px;
}

.products__content {
  display: grid;
}
.categories--wrapper {
  display: grid;
  grid-gap: 17px;
}
.products__content__category {
  max-width: 100vw;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}
.category__header {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 7px;
  padding: 5px;
}
.category__list {
  max-width: calc(100vw - 310px);
  justify-content: center;
  grid-gap: 10px;
  margin: 0 auto;
}

.list--wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-gap: 10px;
}
.hidden {
  display: none !important;
}

@media (max-width: 1025px) {
  .products__categories {
    display: none;
  }
  .products__categories--responsive {
    display: block;
  }
  .products--wrapper {
    grid-template-columns: 1fr;
  }
  .category__list {
    max-width: calc(100vw - 50px);
  }
}

* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  color: #50535b;
  box-sizing: border-box;
}
.App {
  width: 100%;
}
.App > section {
  max-width: 1366px;
  margin: 0 auto;
}
.App-wave {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.ways {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}
.ways__title {
  font-family: 'Staatliches', cursive;
  font-size: 2.5rem;
  color: #ef3934;
  grid-column-start: 1;
  grid-column-end: span 2;
  justify-self: center;
  z-index: 2;
  background-color: #fff;
  text-align: center;
}
.ways__list {
  max-width: 500px;
  width: 100%;
  display: grid;
  align-content: start;
  grid-gap: 30px;
}
.ways__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 25px;
  grid-row-gap: 5px;
}
.way_number {
  position: relative;
  width: 50px;
  height: 50px;
  background: #ef3934;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(239, 57, 52, 0.3);
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-row-start: 1;
  grid-row-end: span 2;
}
.ways__item h3 {
  grid-column-start: 2;
  font-size: 1.125rem;
}
.ways__item p {
  grid-column-start: 2;
  font-size: 1.125rem;
  line-height: 32px;
}
.ways__photo {
  width: 471px;
  height: 380px;
  /* display: grid; */
  justify-self: center;
  text-align: center;
  /* justify-content: center; */
  overflow: hidden;
}
.ways__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.ways__photo svg {
  width: 0;
  height: 0;
}

.features {
  max-width: 100% !important;
  width: 100%;
  margin: 0 0 !important;
  background: #f6f6f7;
}
.features__wrapper {
  max-width: 1366px;
  margin: 0 auto;
  padding: 50px 0;
  display: grid;
}
.features__title {
  font-family: 'Staatliches', cursive;
  font-size: 2.5rem;
  color: #ef3934;
  grid-column-start: 1;
  grid-column-end: span 2;
  justify-self: center;
  margin-bottom: 30px;
}
.features__list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 0 20px;
}
.features__item {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
  position: relative;
  padding: 2rem 3rem 3rem 2rem;
}
.feature_icons {
  position: absolute;
  right: 0;
  padding-right: 20px;
}
.features__item h3 {
  margin-bottom: 10px;
}
.location {
  padding: 50px 0;
}
@media (max-width: 1040px) {
  .hero {
    height: auto;
    height: initial;
    background: #284b76;
  }
  .App-wave {
    display: none;
  }
  .ways {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .ways__title {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .ways__photo {
    display: none;
  }
  .features__list {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
}
@media (max-width: 850px) {
  .App-wave {
    top: 70px;
  }
}

