.arrow {
  line-height: 0;
  position: absolute;
  top: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 22px;
  outline: none;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}
.arrow {
  fill: rgba(69, 91, 117, 0.5);
}
.arrow__next {
  right: -10px;
  border-left: 1px solid rgba(69, 91, 117, 0.2);
}
.arrow__prev {
  left: -10px;
  border-right: 1px solid rgba(69, 91, 117, 0.2);
}

/* Products View */
.products {
  min-height: 100vh;
  background-color: #f6f6f7;
}
.products--wrapper {
  display: grid;
  grid-template-columns: 240px 1fr;
  padding: 30px 15px;
}

/* Categories Component */
.categories {
  display: grid;
}
.products__categories {
  /* padding: 30px; */
  list-style: none;
  padding: 15px;
}
.categories__item {
  font-weight: 600;
  padding: 4px 0;
  font-size: 14px;
  cursor: pointer;
  color: #7d7d7d;
}
.products__categories--responsive {
  display: none;
  width: 500px;
  justify-self: center;
}
.categories__item--responsive {
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  transition: 0.3s;
  padding: 5px;
}
.categories__item--responsive p {
  margin: 0;
  padding: 0;
}
.slick-slide.slick-active.slick-center.slick-current
  .categories__item--responsive {
  max-width: 200px;
  font-weight: bold;
  font-size: 18px;
}

.products__content {
  display: grid;
}
.categories--wrapper {
  display: grid;
  grid-gap: 17px;
}
.products__content__category {
  max-width: 100vw;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
}
.category__header {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 7px;
  padding: 5px;
}
.category__list {
  max-width: calc(100vw - 310px);
  justify-content: center;
  grid-gap: 10px;
  margin: 0 auto;
}

.list--wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-gap: 10px;
}
.hidden {
  display: none !important;
}

@media (max-width: 1025px) {
  .products__categories {
    display: none;
  }
  .products__categories--responsive {
    display: block;
  }
  .products--wrapper {
    grid-template-columns: 1fr;
  }
  .category__list {
    max-width: calc(100vw - 50px);
  }
}
