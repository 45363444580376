@import url('https://fonts.googleapis.com/css?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;1,200;1,300&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  color: #50535b;
  box-sizing: border-box;
}
.App {
  width: 100%;
}
.App > section {
  max-width: 1366px;
  margin: 0 auto;
}
.App-wave {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.ways {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}
.ways__title {
  font-family: 'Staatliches', cursive;
  font-size: 2.5rem;
  color: #ef3934;
  grid-column-start: 1;
  grid-column-end: span 2;
  justify-self: center;
  z-index: 2;
  background-color: #fff;
  text-align: center;
}
.ways__list {
  max-width: 500px;
  width: 100%;
  display: grid;
  align-content: start;
  grid-gap: 30px;
}
.ways__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 25px;
  grid-row-gap: 5px;
}
.way_number {
  position: relative;
  width: 50px;
  height: 50px;
  background: #ef3934;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(239, 57, 52, 0.3);
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-row-start: 1;
  grid-row-end: span 2;
}
.ways__item h3 {
  grid-column-start: 2;
  font-size: 1.125rem;
}
.ways__item p {
  grid-column-start: 2;
  font-size: 1.125rem;
  line-height: 32px;
}
.ways__photo {
  width: 471px;
  height: 380px;
  /* display: grid; */
  justify-self: center;
  text-align: center;
  /* justify-content: center; */
  overflow: hidden;
}
.ways__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.ways__photo svg {
  width: 0;
  height: 0;
}

.features {
  max-width: 100% !important;
  width: 100%;
  margin: 0 0 !important;
  background: #f6f6f7;
}
.features__wrapper {
  max-width: 1366px;
  margin: 0 auto;
  padding: 50px 0;
  display: grid;
}
.features__title {
  font-family: 'Staatliches', cursive;
  font-size: 2.5rem;
  color: #ef3934;
  grid-column-start: 1;
  grid-column-end: span 2;
  justify-self: center;
  margin-bottom: 30px;
}
.features__list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 0 20px;
}
.features__item {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
  position: relative;
  padding: 2rem 3rem 3rem 2rem;
}
.feature_icons {
  position: absolute;
  right: 0;
  padding-right: 20px;
}
.features__item h3 {
  margin-bottom: 10px;
}
.location {
  padding: 50px 0;
}
@media (max-width: 1040px) {
  .hero {
    height: initial;
    background: #284b76;
  }
  .App-wave {
    display: none;
  }
  .ways {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .ways__title {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .ways__photo {
    display: none;
  }
  .features__list {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
}
@media (max-width: 850px) {
  .App-wave {
    top: 70px;
  }
}
